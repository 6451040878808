import React, { useRef, useEffect } from 'react';

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface ChatUIProps {
  messages: Message[];
  accumulatedTranscript: string;
  isTranscriptFinal: boolean;
}

const ChatUI: React.FC<ChatUIProps> = ({ messages, accumulatedTranscript, isTranscriptFinal }) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, accumulatedTranscript]);

  return (
    <div
      ref={chatContainerRef}
      style={{
        height: '100%',
        overflowY: 'auto',
        border: '1px solid #ccc',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {messages.map((message, index) => (
        <div
          key={index}
          style={{
            alignSelf: message.role === 'user' ? 'flex-end' : 'flex-start',
            backgroundColor: message.role === 'user' ? '#dcf8c6' : '#f1f0f0',
            borderRadius: '10px',
            padding: '8px 12px',
            margin: '4px 0',
            maxWidth: '70%',
          }}
        >
          {message.content.split('\n').map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
              {line}
              {lineIndex < message.content.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      ))}
      {accumulatedTranscript && (
        <div
          style={{
            alignSelf: 'flex-end',
            backgroundColor: isTranscriptFinal ? '#dcf8c6' : '#a7d8ff',
            borderRadius: '10px',
            padding: '8px 12px',
            margin: '4px 0',
            maxWidth: '70%',
            opacity: 1,
            transition: 'background-color 0.3s ease',
          }}
        >
          {accumulatedTranscript}
        </div>
      )}
    </div>
  );
};

export default ChatUI;