import { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { debounce } from 'lodash';
import { AvatarClient } from 'lib/avatar/AvatarClient';
import { AzureSpeechRecognition } from 'lib/speech/AzureSpeechRecognition';
import ChatUI from './components/ChatUI';
import { useApiState } from './hooks/useApiState';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;
const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

if (!TUTOR_API_BACKEND_URL) {
  throw new Error('REACT_APP_TUTOR_API_BACKEND_URL environment variable is not defined');
}

if (!WEBSOCKET_URL) {
  throw new Error('REACT_APP_WEBSOCKET_URL environment variable is not defined');
}

export function App() {
  const { apiState, fetchInitialState, updateApiState } = useApiState();
  const avatarIdRef = useRef<number | undefined>(undefined);
  const avatar = useMemo(() => new AvatarClient({
    apiKey: process.env.REACT_APP_AVATAR_API_KEY || '',
    baseUrl: process.env.REACT_APP_AVATAR_BASE_URL || '',
    avatarId: avatarIdRef.current
  }), []);

  const speechRecognition = useMemo(() => new AzureSpeechRecognition(), []);

  const [conversationHistory, setConversationHistory] = useState<{ role: 'user' | 'assistant'; content: string }[]>([]);
  const [accumulatedTranscript, setAccumulatedTranscript] = useState('');
  const [conversationStarted, setConversationStarted] = useState(false);
  const [isTranscriptFinal, setIsTranscriptFinal] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const isReadyRef = useRef(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const wsRef = useRef<WebSocket | null>(null);
  const isAvatarInitializedRef = useRef(false);
  const [isAvatarVideoEnabled, setIsAvatarVideoEnabled] = useState(true);
  const [isAvatarVoiceEnabled, setIsAvatarVoiceEnabled] = useState(true);
  const [isTextBoxEnabled, setIsTextBoxEnabled] = useState(true);
  const speakingRateRef = useRef<number>(1.0);

  const initializeAvatar = useCallback(async () => {
    isAvatarInitializedRef.current = false;
    if (videoRef.current && audioRef.current && avatarIdRef.current !== null) {
      try {
        console.log('Initializing avatar...');
        await avatar.init(videoRef.current, audioRef.current);
        console.log('Avatar initialized');
        console.log('Initializing connection with avatarId:', avatarIdRef.current);
        await avatar.connect(avatarIdRef.current);
        console.log('Avatar connected');
        isAvatarInitializedRef.current = true;
      } catch (error) {
        console.error("Error initializing avatar:", error);
      }
    }
    console.log('initializeAvatar function executed');
  }, [avatar, videoRef, audioRef]);

  const speakResponse = useCallback(async (text: string) => {
    console.log('Speaking response:', text);
    const sentences = text.split(/(?<=[.!?])\s+/).map(sentence => sentence.trim()).filter(sentence => sentence);
    console.log('Speaking rate:', speakingRateRef.current);
    for (const sentence of sentences) {
      try {
        await avatar.say(sentence, {
          prosody: {
            rate: `${speakingRateRef.current}`
          }
        });
      } catch (error) {
        console.error('Error in avatar.say:', error);
        await avatar.say(sentence)
      }
    }
  }, [avatar]);

  const debouncedHandleUserInput = useMemo(() => 
    debounce(async (userInput: string) => {
      console.log('User input:', userInput, 'SessionId:', sessionId);
      if (!sessionId) {
        console.warn('No sessionId found, skipping conversation request');
        return;
      }
  
      if (!conversationStarted) {
        setConversationStarted(true);
      }
  
      setConversationHistory(prev => [...prev, { role: 'user', content: userInput }]);
      setAccumulatedTranscript('');
  
      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/conversation/${sessionId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: userInput
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          const assistantResponse = data.response.content;
          setConversationHistory(prev => [...prev, { role: 'assistant', content: assistantResponse }]);
          await speakResponse(assistantResponse);
        } else {
          const errorText = await response.text();
          throw new Error(`Server responded with ${response.status}: ${errorText}`);
        }
      } catch (error) {
        console.error("Error getting LLM response:", error);
      }
    }, 300), [sessionId, conversationStarted, speakResponse]);
  
  const handleUserInput = useCallback(
    (userInput: string) => debouncedHandleUserInput(userInput),
    [debouncedHandleUserInput]
  );  

  const onSpeechRecognized = useCallback((text: string, isFinal: boolean) => {
    if (text.trim() && text.trim().toLowerCase() !== "play." && text.trim().toLowerCase() !== "i.") {
      if (avatar.isSpeaking) {
        avatar.stop();
      }

      setIsTranscriptFinal(isFinal);

      if (isFinal) {
        setAccumulatedTranscript(text.trim());
        handleUserInput(text.trim());
      } else {
        setAccumulatedTranscript(text.trim());
      }
    }
  }, [handleUserInput, avatar]);

  const processAvatarSpeakIntro = useCallback((message: string) => {
    console.log('Processing avatar speak intro');
    const checkAvatarReady = () => {
      return isAvatarInitializedRef.current && avatar.isConnected && isReadyRef.current;
    };

    const speakIntro = async () => {
      console.log('Avatar ready, waiting 500ms before speaking intro');
      await new Promise(resolve => setTimeout(resolve, 500));
      console.log('Speaking intro');
      await speakResponse(message);
      setTimeout(() => {
        setConversationHistory(prev => [...prev, { role: 'assistant', content: message }]);
      }, 1000);
    };

    if (checkAvatarReady()) {
      speakIntro();
    } else {
      console.log('Avatar not ready, waiting...');
      const interval = setInterval(() => {
        if (checkAvatarReady()) {
          clearInterval(interval);
          speakIntro();
        } else {
          console.log('Avatar not ready, continuing to wait...');
        }
      }, 100);
    }
  }, [avatar, speakResponse, setConversationHistory]);  

  const handleMessageRef = useRef<((event: MessageEvent) => void) | null>(null);

  const initSession = useCallback(async () => {
    if (!wsRef.current) {
      const ws = new WebSocket(WEBSOCKET_URL!);
      wsRef.current = ws;
  
      ws.onopen = () => {
        console.log('WebSocket connection established');
      };
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
      ws.onclose = (event) => {
        console.log('WebSocket connection closed', event);
      };
      ws.onmessage = (event: MessageEvent) => {
        if (handleMessageRef.current) {
          const message = JSON.parse(event.data);
          console.log('WebSocket message:', message);
          handleMessageRef.current(event);
        }
      };
  
      isReadyRef.current = true;
    }
  }, []);

  const startSpeechRecognition = useCallback(async () => {
    console.log('Starting speech recognition');
    if (speechRecognition.isRunning()) {
      console.log('Speech recognition is already running');
      return;
    }
    try {
      await speechRecognition.start(
        process.env.REACT_APP_AZURE_SPEECH_KEY || '',
        process.env.REACT_APP_AZURE_SPEECH_REGION || '',
        onSpeechRecognized,
        () => null
      );
      setAccumulatedTranscript('');
    } catch (error) {
      console.error("Error starting speech recognition:", error);
    }
  }, [speechRecognition, onSpeechRecognized]);

  const stopSpeechRecognition = useCallback(async () => {
    console.log('Stopping speech recognition');
    if (!speechRecognition.isRunning()) {
      console.log('Speech recognition is already stopped');
      return;
    }
    try {
      await speechRecognition.stop();
      setAccumulatedTranscript('');
    } catch (error) {
      console.error("Error stopping speech recognition:", error);
    }
  }, [speechRecognition]);

  const toggleSpeechRecognition = useCallback(async () => {
    console.log('Toggling speech recognition');
    if (speechRecognition.isRunning()) {
      await stopSpeechRecognition();
    } else {
      await startSpeechRecognition();
    }
  }, [speechRecognition, startSpeechRecognition, stopSpeechRecognition]);

  const endSession = useCallback(async () => {
    await avatar.disconnect();
    await stopSpeechRecognition();
    setSessionId(null);
    setConversationStarted(false);
    setConversationHistory([]);
    setAccumulatedTranscript('');
    setIsTranscriptFinal(true);
    isReadyRef.current = false;
    isAvatarInitializedRef.current = false;
    // Clean up WebSocket connection
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
  }, [avatar, wsRef, stopSpeechRecognition]);

  const switchAvatar = useCallback(async (newAvatarId: number) => {
    console.log('Switching avatar...');
    isAvatarInitializedRef.current = false;
    await avatar.disconnect();
    try {
      if (videoRef.current && audioRef.current) {
        await avatar.init(videoRef.current, audioRef.current);
        await avatar.connect(newAvatarId);
        console.log('Avatar switched and connected');
        isAvatarInitializedRef.current = true;
      } else {
        console.error('Video or audio element is null');
      }
    } catch (error) {
      console.error("Error switching avatar:", error);
    }
  }, [avatar, videoRef, audioRef]);

  const handleMessage = useCallback(async (event: MessageEvent) => {
    const messageData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
    const { type, sessionId: messageSessionId, avatarId, speakingRate } = messageData;
    console.log('Received message:', event.data, 'SessionId:', sessionId, 'MessageSessionId:', messageSessionId);
    if (messageSessionId !== sessionId) return;

    if (type === 'INIT_AUDIO') {
      console.log('INIT_AUDIO message received');
      await initSession();
    } else if (type === 'END_SESSION') {
      console.log('END_SESSION message received');
      await endSession();
    } else if (type === 'START_SPEECH_RECOGNITION') {
      console.log('START_SPEECH_RECOGNITION message received');
      await startSpeechRecognition();
    } else if (type === 'STOP_SPEECH_RECOGNITION') {
      console.log('STOP_SPEECH_RECOGNITION message received');
      await stopSpeechRecognition();
    } else if (type === 'TOGGLE_SPEECH_RECOGNITION') {
      console.log('TOGGLE_SPEECH_RECOGNITION message received');
      await toggleSpeechRecognition();
    } else if (type === 'ENABLE_AVATAR_VIDEO') {
      console.log('ENABLE_AVATAR_VIDEO message received');
      setIsAvatarVideoEnabled(true);
    } else if (type === 'DISABLE_AVATAR_VIDEO') {
      console.log('DISABLE_AVATAR_VIDEO message received');
      setIsAvatarVideoEnabled(false);
    } else if (type === 'TOGGLE_AVATAR_VIDEO') {
      console.log('TOGGLE_AVATAR_VIDEO message received');
      setIsAvatarVideoEnabled(prev => !prev);
    } else if (type === 'ENABLE_AVATAR_VOICE') {
      console.log('ENABLE_AVATAR_VOICE message received');
      setIsAvatarVoiceEnabled(true);
    } else if (type === 'DISABLE_AVATAR_VOICE') {
      console.log('DISABLE_AVATAR_VOICE message received');
      setIsAvatarVoiceEnabled(false);
    } else if (type === 'TOGGLE_AVATAR_VOICE') {
      console.log('TOGGLE_AVATAR_VOICE message received');
      setIsAvatarVoiceEnabled(prev => !prev);
    } else if (type === 'ENABLE_TEXT_BOX') {
      console.log('ENABLE_TEXT_BOX message received');
      setIsTextBoxEnabled(true);
    } else if (type === 'DISABLE_TEXT_BOX') {
      console.log('DISABLE_TEXT_BOX message received');
      setIsTextBoxEnabled(false);
    } else if (type === 'TOGGLE_TEXT_BOX') {
      console.log('TOGGLE_TEXT_BOX message received');
      setIsTextBoxEnabled(prev => !prev);
    } else if (type === 'CHANGE_AVATAR') {
      console.log('CHANGE_AVATAR message received');
      await switchAvatar(avatarId);
    } else if (type === 'AVATAR_SPEAK_INTRO') {
      console.log('AVATAR_SPEAK_INTRO message received');
      processAvatarSpeakIntro(messageData.message);
    } else if (type === 'HANDLE_MESSAGE') {
      console.log('HANDLE_MESSAGE message received');
      if (avatar.isSpeaking) {
        avatar.stop();
      }
      handleUserInput(messageData.message);
    } else if (type === 'CHANGE_SPEAKING_RATE') {
      console.log('CHANGE_SPEAKING_RATE message received:', speakingRate);
      updateApiState({ speakingRate });
      speakingRateRef.current = speakingRate;
    } else if (type === 'STOP_SPEAKING') {
      console.log('STOP_SPEAKING message received');
      avatar.stop();
    }
  }, [avatar, sessionId, initSession, endSession, startSpeechRecognition, stopSpeechRecognition, toggleSpeechRecognition, switchAvatar, processAvatarSpeakIntro, handleUserInput, updateApiState]);

  useEffect(() => {
    handleMessageRef.current = handleMessage;
  }, [handleMessage]);

  useEffect(() => {
    if (apiState) {
      console.log('Updating avatarIdRef:', apiState.avatarId);
      avatarIdRef.current = apiState.avatarId;
    }
  }, [apiState]);

  useEffect(() => {
    if (apiState?.speakingRate !== undefined) {
      speakingRateRef.current = apiState.speakingRate;
    }
  }, [apiState?.speakingRate]);

  useEffect(() => {
    if (handleMessageRef.current) {
      window.addEventListener('message', handleMessageRef.current);
    }
    
    // Signal to the parent that the iframe is loaded
    window.parent.postMessage({ type: 'IFRAME_LOADED' }, '*');

    return () => {
      if (handleMessageRef.current) {
        window.removeEventListener('message', handleMessageRef.current);
      }
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [sessionId, handleMessageRef]);

  useEffect(() => {
    if (isReadyRef.current && apiState) {
      const initializeAndStartRecognition = async () => {
        await initializeAvatar();
        await startSpeechRecognition();
      };
      if (!isAvatarInitializedRef.current && !speechRecognition.isRunning()) {
        console.log('Initializing and starting recognition');
        initializeAndStartRecognition();
      }
    } else {
      console.log('isReady or apiState is false, skipping initialization');
    }
  }, [apiState, speechRecognition, initializeAvatar, startSpeechRecognition]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('sessionId');
    console.log('URL params:', urlParams.toString());
    console.log('Extracted sessionId:', id);
    if (id) {
      setSessionId(id);
      console.log('SessionId set:', id);
      fetchInitialState(id);
    } else {
      console.warn('No sessionId found in URL parameters');
    }
  }, [fetchInitialState]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = isAvatarVoiceEnabled ? 1 : 0;
    }
  }, [isAvatarVoiceEnabled]);

  const getContainerStyle = useCallback(() => {
    return {
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      padding: '0px',
      boxSizing: 'border-box' as const,
    };
  }, []);

  const getContentContainerStyle = useCallback(() => {
    const contentHeight = isTextBoxEnabled ? '95vh' : '100vh';
    return {
      width: contentHeight,
      height: contentHeight,
      display: 'flex',
      flexDirection: 'column' as const,
      justifyContent: 'center',
      alignItems: 'center',
    };
  }, [isTextBoxEnabled]);

  const getVideoContainerStyle = useCallback(() => {
    const size = isTextBoxEnabled ? '67%' : '100%';
    return {
      width: size,
      height: size,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  }, [isTextBoxEnabled]);

  const getVideoStyle = useCallback(() => {
    return {
      width: '100%',
      height: '100%',
      objectFit: 'contain' as const,
      display: isAvatarVideoEnabled ? 'block' : 'none',
    };
  }, [isAvatarVideoEnabled]);

  const getTextBoxStyle = useCallback(() => {
    const size = isTextBoxEnabled ? '67%' : '100%';
    return {
      width: size,
      height: '40%',
      objectFit: 'contain' as const,
    };
  }, [isTextBoxEnabled]);

  if (!apiState) {
    return <div>Loading...</div>;
  }

  return (
    <div style={getContainerStyle()}>
      <div style={getContentContainerStyle()}>
        <div style={getVideoContainerStyle()}>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            style={getVideoStyle()}
          />
          <audio ref={audioRef} style={{ display: isAvatarVoiceEnabled ? 'block' : 'none' }} autoPlay />
        </div>
        {isTextBoxEnabled && (
          <div style={getTextBoxStyle()}>
            <ChatUI
              messages={conversationHistory}
              accumulatedTranscript={accumulatedTranscript}
              isTranscriptFinal={isTranscriptFinal}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;