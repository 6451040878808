import { useState, useCallback } from 'react';
import { APIExposedState } from '../types/apiTypes';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;

if (!TUTOR_API_BACKEND_URL) {
  throw new Error('REACT_APP_TUTOR_API_BACKEND_URL environment variable is not defined');
}

export const useApiState = () => {
  const [apiState, setApiState] = useState<APIExposedState | null>(null);

  const updateApiState = useCallback((updates: Partial<APIExposedState>) => {
    setApiState(prevState => (prevState ? { ...prevState, ...updates } : prevState));
  }, []);

  const fetchInitialState = useCallback(async (sessionId: string) => {
    try {
      const response = await fetch(`${TUTOR_API_BACKEND_URL}/session/${sessionId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch initial state: ${response.statusText}`);
      }
      const initialState = await response.json();
      setApiState(initialState);
    } catch (error) {
      console.error('Error fetching initial state:', error);
    }
  }, []);

  return { apiState, updateApiState, fetchInitialState };
};